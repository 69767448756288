import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import Architekturflaeche1 from "../components/architekturflaeche1"
import Architekturflaeche2 from "../components/architekturflaeche2"
import Architekturflaeche3 from "../components/architekturflaeche3"
import Architekturflaeche4 from "../components/architekturflaeche4"
import Architekturflaeche5 from "../components/architekturflaeche5"

import "./index.css"

export default function ArchitekturflaechePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Architekturflaeche"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Architekturflaeche"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Architektur<wbr/>oberfläche</h1>
        <h2 className="referenceH2">
          Die Wallfahrtskirche „Zum Gegeißelten Heiland auf der Wies“
        </h2>
        <h3 className="referenceH3">
          Konservierung der Blattmetallauflagen, Polimentblattvergoldung,
          Strichretusche mit Farbe und Pudergold
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Architekturflaeche1">
              <Architekturflaeche1 id="Architekturflaeche1" />
              Deckenbildrahmen nach der Fertigstellung
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Architekturflaeche2">
              <Architekturflaeche2 id="Architekturflaeche2" />
              Deckenbildrahmen im Zwischenzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Architekturflaeche3">
              <Architekturflaeche3 id="Architekturflaeche3" />
              Deckenbildrahmen im Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Architekturflaeche4">
              <Architekturflaeche4 id="Architekturflaeche4" />
              Durch Wassereintrag geschädigter Brokat im Zentralraum
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Architekturflaeche5">
              <Architekturflaeche5 id="Architekturflaeche5" />
              Strichretusche der Farbfassung und Pudergoldretusche der
              Mordantvergoldung
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Die Wallfahrtskirche „Zum Gegeißelten Heiland auf der Wies“ wurde
              von den Brüdern Dominikus und Johann Baptist Zimmermann 1745–1754
              erbaut. Seit 1983 zählt Wieskirche als bedeutendes Bauwerk zu den
              Weltkulturerbestätten und ist in die UNESCO-Liste eingetragen.
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Die Vergoldung des Deckenbildrahmens im Zentralraum wurde um 1900
              mit einer <nobr>Leimvergoldung,</nobr> deren Bindemittel sich
              abgebaut hat, ausgeführt. Die Farbfassung der Brokate war stark
              reduziert.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Nach deren Entfernung sollte die Vergoldung mit Blattgold im
              Duktus der Rokokofassung rekonstruiert werden. Vorgehensweise:
              Bedeutsam war, dass man den Kreidegrundauftrag entsprechend dem
              Original imitierte. Deshalb sollte der Untergrund lebendig bleiben
              und nicht geschliffen werden.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Die aufschollenden Fragmente originaler Vergoldung wurden
              gefestigt. Die krepierte Vergoldung der damals ausführenden Firma
              wurde abgenommen und ein Untergrund für die Neuvergoldung
              geschaffen. Um den authentischen Vergoldungscharakter
              wiederherzustellen, wurde die Farbe des Blattgoldes definiert und
              das originale Blattgoldmaß entsprechend des Originalbefundes
              hergestellt. Ebenso musste der farbintensive Polimentfarbton und
              der Anschussduktus einer Rokokovergoldung übernommen werden.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">Arge Wies, Leitung Klaus Klarner.</p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText">
              Landesamt für Denkmalpflege, Dr. Rainer Schmidt, Werkstattleiter
              für den Fachbereich Wand Restaurator Jürgen Pursche.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
